import { Injectable } from '@angular/core';
import { createEffect, Actions } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { ofAction } from '../ngrx-actions/of-action';
import { of, from } from 'rxjs';
import { catchError, switchMap, map, takeUntil, delay, withLatestFrom } from 'rxjs/operators';

import { NgxAlertService, getMessageFromError } from 'ngx-shared';
import { NGXLogger } from 'ngx-logger';
import { KpiService } from '@/_services';
import * as actions from './kpi.actions';
import { KPIExportStatuses } from '@/_models';
import { AppStore } from '../app-state';

@Injectable()
export class KpiEffects {
    
    public onServerFetchSectionList$ = createEffect(() => this.actions$.pipe(
        ofAction(actions.FetchSectionListAction),
        switchMap(() => this.kpiService.getSectionList().pipe(
            switchMap(y => [new actions.LoadInStateSectionListAction(y), new actions.FetchOkSectionListAction()]),
            catchError(error => {
                this.logger.error('Could not Fetch Section list', error);
                this.alertService.error(getMessageFromError(error));
                return of(new actions.FetchFailedSectionListAction(error));
            })
        ))
    ));

    public onServerFetchExportSection$ = createEffect(() => this.actions$.pipe(
        ofAction(actions.FetchExportSectionAction),
        switchMap(() => this.kpiService.getExportedSectionList().pipe(
            switchMap(x => [new actions.LoadInStateSectionExportListAction(x), new actions.FetchOkExportSectionAction()]),
            catchError(error => {
                this.logger.error('Could not Fetch ExportSection', error);
                this.alertService.error(getMessageFromError(error));
                return of(new actions.FetchFailedExportSectionAction(error));
            })
        ))
    ));

    
    public onClearSectionList$ = createEffect(() => this.actions$.pipe(
        ofAction(actions.ClearSectionListAction),
        map(() => new actions.LoadInStateSectionListAction())
    ));

    //#region overrides
    
    public onServerFetchOverrides$ = createEffect(() => this.actions$.pipe(
        ofAction(actions.FetchOverridesAction),
        switchMap(x => this.kpiService.getOverrides(x.sectionId, x.targetId).pipe(
            switchMap(y => [new actions.LoadInStateOverridesAction(y), new actions.FetchOkOverridesAction()]),
            catchError(error => {
                this.logger.error('Could not Fetch Overrides', error);
                this.alertService.error(getMessageFromError(error));
                return of(new actions.FetchFailedOverridesAction(error));
            })
        ))
    ));

    
    public onClearOverrides$ = createEffect(() => this.actions$.pipe(
        ofAction(actions.ClearOverridesAction),
        map(() => new actions.LoadInStateOverridesAction())
    ));

    
    public onServerCreateOverride$ = createEffect(() => this.actions$.pipe(
        ofAction(actions.CreateOverrideAction),
        switchMap(x => this.kpiService.createOverride(x.override).pipe(
            switchMap(y => [new actions.AddInStateOverrideAction(y), new actions.CreateOkOverrideAction()]),
            catchError(error => {
                this.logger.error('Could not Create KpiOverride', error);
                this.alertService.error(getMessageFromError(error));
                return of(new actions.CreateFailedOverrideAction(error));
            })
        ))
    ));

    
    public onServerUpdateOverride$ = createEffect(() => this.actions$.pipe(
        ofAction(actions.UpdateOverrideAction),
        switchMap(x => this.kpiService.updateOverride(x.override).pipe(
            switchMap(y => [new actions.UpdateInStateOverrideAction(y), new actions.UpdateOkOverrideAction()]),
            catchError(error => {
                this.logger.error('Could not Update KpiOverride', error);
                this.alertService.error(getMessageFromError(error));
                return of(new actions.UpdateFailedOverrideAction(error));
            })
        ))
    ));

    
    public onServerDeleteOverride$ = createEffect(() => this.actions$.pipe(
        ofAction(actions.DeleteOverrideAction),
        switchMap(x => this.kpiService.deleteOverride(x.id).pipe(
            switchMap(() => [new actions.DeleteInStateOverrideAction(x.id), new actions.DeleteOkOverrideAction()]),
            catchError(error => {
                this.logger.error('Could not Delete KpiOverride', error);
                this.alertService.error(getMessageFromError(error));
                return of(new actions.DeleteFailedOverrideAction(error));
            })
        ))
    ));
    //#endregion overrides

    //#region issues
    
    public onServerIssuesUpdateProblemTimeFlag$ = createEffect(() => this.actions$.pipe(
        ofAction(actions.UpdateIssuesProblemTimeFlagAction),
        switchMap(x => this.kpiService.updateProblemTimeFlag(x.issueIds, x.isProblemTime).pipe(
            switchMap(() => [
                new actions.UpdateInStateIssuesProblemTimeFlagAction(x.issueIds, x.isProblemTime),
                new actions.UpdateIssuesProblemTimeFlagOkAction()
            ]),
            catchError(error => {
                this.logger.error('Could not update Kpi issues problem time flag', error);
                this.alertService.error(getMessageFromError(error));
                return of(new actions.UpdateIssuesProblemTimeFlagFailedAction(error));
            })
        ))
    ));

    
    public onServerAllIssuesUpdateProblemTimeFlag$ = createEffect(() => this.actions$.pipe(
        ofAction(actions.UpdateAllIssuesProblemTimeFlagAction),
        switchMap(x => this.kpiService.updateAllProblemTimeFlag(x.sectionId, x.targetId, x.isProblemTime).pipe(
            switchMap(() => [
                new actions.UpdateInStateAllIssuesProblemTimeFlagAction(x.isProblemTime),
                new actions.UpdateAllIssuesProblemTimeFlagOkAction()
            ]),
            catchError(error => {
                this.logger.error('Could not update all Kpi issues problem time flag', error);
                this.alertService.error(getMessageFromError(error));
                return of(new actions.UpdateAllIssuesProblemTimeFlagFailedAction(error));
            })
        ))
    ));

    
    public onServerFetchIssueBatch$ = createEffect(() => this.actions$.pipe(
        ofAction(actions.FetchKpiIssueBatchAction),
        switchMap(x => [
            new actions.StartFetchKpiIssueBatchAction(),
            new actions.ContinueFetchKpiIssueBatchAction(x.sectionId, x.targetId, null)
        ])
    ));

    
    public onContinueServerFetchIssueBatch$ = createEffect(() => this.actions$.pipe(
        ofAction(actions.ContinueFetchKpiIssueBatchAction),
        switchMap(x => this.kpiService.getIssueBatch(x.sectionId, x.targetId, x.nexStep).pipe(
            switchMap(y => {
                const result: Action[] = [new actions.InsertInStateKpiIssueBatchAction(y.issues)];
                if (y.nextStep) {
                    result.push(new actions.ContinueFetchKpiIssueBatchAction(x.sectionId, x.targetId, y.nextStep));
                } else {
                    result.push(new actions.FinishFetchKpiIssueBatchAction());
                }
                return result;
            }),
            catchError(error => {
                this.logger.error('Could not Fetch kpi issue batch', error);
                this.alertService.error(getMessageFromError(error));
                return from([new actions.FetchKpiIssueBatchFailAction(error), new actions.FinishFetchKpiIssueBatchAction()]);
            }),
            takeUntil(this.actions$.pipe(ofAction(actions.CancelFetchKpiIssueBatchAction)))
        ))
    ));

    
    public onClearIssueBatch$ = createEffect(() => this.actions$.pipe(
        ofAction(actions.ClearIssueBatchAction),
        map(() => new actions.InsertInStateKpiIssueBatchAction())
    ));
    //#endregion issues

    
    public onServerFetchIssueReasonCode = createEffect(() => this.actions$.pipe(
        ofAction(actions.FetchIssueReasonCodesAction),
        switchMap(() => this.kpiService.getIssueReasonCodes().pipe(
            switchMap(y => [new actions.LoadInStateIssueReasonCodesAction(y), new actions.FetchOkIssueReasonCodesAction()]),
            catchError(error => {
                this.logger.error('Could not Fetch issue reason code', error);
                this.alertService.error(getMessageFromError(error));
                return of(new actions.FetchFailedIssueReasonCodesAction(error));
            })
        ))
    ));

    //#region chart
    
    public onServerFetchKpiChartData = createEffect(() => this.actions$.pipe(
        ofAction(actions.FetchKpiChartDataAction),
        switchMap(x => this.kpiService.getKpiChartData(x.sectionId).pipe(
            switchMap(y => [new actions.LoadInStateKpiChartDataAction(y), new actions.FetchOkKpiChartDataAction()]),
            catchError(error => {
                this.logger.error('Could not Fetch kpi chart data', error);
                this.alertService.error(getMessageFromError(error));
                return of(new actions.FetchFailedKpiChartDataAction(error));
            })
        ))
    ));

    
    public onClearChart$ = createEffect(() => this.actions$.pipe(
        ofAction(actions.ClearInStateKpiChartDataAction),
        map(() => new actions.LoadInStateKpiChartDataAction())
    ));
    //#endregion chart

    
    public onServerFetchSectionWithRuleInstances$ = createEffect(() => this.actions$.pipe(
        ofAction(actions.FetchSectionWithRuleInstancesAction),
        switchMap(x => this.kpiService.getSectionWithRuleInstanceData(x.sectionId, x.targetId).pipe(
            switchMap(y => [new actions.LoadInStateSectionWithRuleInstancesAction(y), new actions.FetchOkSectionWithRuleInstancesAction()]),
            catchError(error => {
                this.logger.error('Could not Fetch section with rule instances', error);
                this.alertService.error(getMessageFromError(error));
                return of(new actions.FetchFailedSectionWithRuleInstancesAction(error));
            })
        ))
    ));

    
    public onClearSectionWithRuleInstances$ = createEffect(() => this.actions$.pipe(
        ofAction(actions.ClearSectionWithRuleInstancesAction),
        map(() => new actions.LoadInStateSectionWithRuleInstancesAction())
    ));

    //#region update section range
    
    public onServerCreateSectionTimeRange$ = createEffect(() => this.actions$.pipe(
        ofAction(actions.CreateSectionTimeRangeAction),
        switchMap(x => this.kpiService.createSectionDateRange(x.sectionId, x.targetId, x.range).pipe(
            switchMap(y => [new actions.InsertInStateSectionTimeRangeAction(y), new actions.CreateOkSectionTimeRangeAction()]),
            catchError(error => {
                this.logger.error('Could not Create section time range', error);
                this.alertService.error(getMessageFromError(error));
                return of(new actions.CreateFailedSectionTimeRangeAction(error));
            })
        ))
    ));
    
    public onServerUpdateSectionTimeRange$ = createEffect(() => this.actions$.pipe(
        ofAction(actions.UpdateSectionTimeRangeAction),
        switchMap(x => this.kpiService.updateSectionDateRange(x.range).pipe(
            switchMap(y => [new actions.UpdateInStateSectionTimeRangeAction(y), new actions.UpdateOkSectionTimeRangeAction()]),
            catchError(error => {
                this.logger.error('Could not Update section time range', error);
                this.alertService.error(getMessageFromError(error));
                return of(new actions.UpdateFailedSectionTimeRangeAction(error));
            })
        ))
    ));
    
    public onServerDeleteSectionTimeRange$ = createEffect(() => this.actions$.pipe(
        ofAction(actions.DeleteSectionTimeRangeAction),
        switchMap(x => this.kpiService.deleteSectionDateRange(x.range.id).pipe(
            switchMap(() => [new actions.DeleteInStateSectionTimeRangeAction(x.range), new actions.DeleteOkSectionTimeRangeAction()]),
            catchError(error => {
                this.logger.error('Could not Delete section time range', error);
                this.alertService.error(getMessageFromError(error));
                return of(new actions.DeleteFailedSectionTimeRangeAction(error));
            })
        ))
    ));
    //#endregion update section range

    
    public onServerFetchKpiSummary$ = createEffect(() => this.actions$.pipe(
        ofAction(actions.FetchKpiSummaryAction),
        switchMap(x => this.kpiService.getSummary(x.sectionId, x.targetId).pipe(
            switchMap((y) => [new actions.LoadInStateKpiExportSummaryAction(y), new actions.FetchOkKpiSummaryAction()]),
            catchError(error => {
                this.logger.error('Could not Fetch kpi exportSummary', error);
                this.alertService.error(getMessageFromError(error));
                return of(new actions.FetchFailedKpiSummaryAction(error));
            })
        ))
    ));

    
    public onClearSummary$ = createEffect(() => this.actions$.pipe(
        ofAction(actions.ClearInStateKpiSummaryAction),
        map(() => new actions.LoadInStateKpiExportSummaryAction())
    ));
        
    public onServerFetchDapKpiSummary$ = createEffect(() => this.actions$.pipe(
        ofAction(actions.FetchDapKpiSummaryAction),
        switchMap(x => this.kpiService.getDapSummary(x.sectionId, x.targetId).pipe(
            switchMap((y) => [new actions.LoadInStateDapKpiExportSummaryAction(y), new actions.FetchOkDapKpiSummaryAction()]),
            catchError(error => {
                this.logger.error('Could not Fetch dap kpi summary', error);
                this.alertService.error(getMessageFromError(error));
                return of(new actions.FetchFailedDapKpiSummaryAction(error));
            })
        ))
    ));
    
    public onClearDapSummary$ = createEffect(() => this.actions$.pipe(
        ofAction(actions.ClearInStateDapKpiSummaryAction),
        map(() => new actions.LoadInStateDapKpiExportSummaryAction())
    ));

    public onServerFetchLiveTimeRanges$ = createEffect(() => this.actions$.pipe(
        ofAction(actions.FetchLiveTimeRangesAction),
        switchMap(x => this.kpiService.getLiveTimeRanges(x.sectionId, x.targetId).pipe(
            switchMap((y) => [new actions.LoadInStateLiveTimeRangesAction(y), new actions.FetchOkLiveTimeRangesAction()]),
            catchError(error => {
                this.logger.error('Could not Fetch live time ranges', error);
                this.alertService.error(getMessageFromError(error));
                return of(new actions.FetchFailedLiveTimeRangesAction(error));
            })
        ))
    ));

    public onServerStartKpiExport$ = createEffect(() => this.actions$.pipe(
        ofAction(actions.StartKpiExportAction),
        switchMap(x => this.kpiService.startKpiExport(x.sectionId, x.targetId, x.applyColouringRules, x.createChartData).pipe(
            switchMap(() => [
                new actions.UpdateInStateKpiExportFlagAction(x.sectionId, x.targetId),
                // todo: probably the startKpiExport should return a status
                new actions.UpdateKpiExportStatusAction('InProgress', 'Starting', 10),
                new actions.StartOkKpiExportAction(),
                new actions.MarkKpiExportAsTracked(x.sectionId, x.targetId),
                new actions.TrackKpiExportStatusAction(x.sectionId, x.targetId)
            ]),
            catchError(error => {
                this.logger.error('Could not Start KpiExport', error);
                this.alertService.error(getMessageFromError(error));
                return of(new actions.StartFailedKpiExportAction(error));
            })
        ))
    ));

    
    public onServerFinalizeKpiExport$ = createEffect(() => this.actions$.pipe(
        ofAction(actions.FinalizeKpiExportAction),
        switchMap(x => this.kpiService.finalizeKpiExport(x.sectionId, x.targetId).pipe(
            switchMap(() => [
                new actions.UpdateInStateKpiFinalizeAction(x.sectionId, x.targetId, true),
                new actions.FinalizeOkKpiExportAction()]),
            catchError(error => {
                this.logger.error('Could not Finalize KpiExport', error);
                this.alertService.error(getMessageFromError(error));
                return of(new actions.FinalizeFailedKpiExportAction(error));
            })
        ))
    ));

    
    public onServerCheckKpiExportStatus$ = createEffect(() => this.actions$.pipe(
        ofAction(actions.CheckKpiExportStatusAction),
        switchMap(x => this.kpiService.getKpiExportStatus(x.sectionId, x.targetId).pipe(
            switchMap((y) => [new actions.UpdateKpiExportStatusAction(y.status, y.reason, y.progress),
                 new actions.CheckOkKpiExportStatusAction()]),
            catchError(error => {
                this.logger.error('Could not check KpiExport status', error);
                this.alertService.error(getMessageFromError(error));
                return of(new actions.CheckFailedKpiExportStatusAction(error));
            })
        ))
    ));

    public onTrackKpiExportStatusAction$ = createEffect(() => this.actions$.pipe(
        ofAction(actions.TrackKpiExportStatusAction),
        delay(1000),
        withLatestFrom(this.store.select(state => state.kpiState.exportTracking)),
        switchMap(([x, t]) => this.kpiService.getKpiExportStatus(x.sectionId, x.targetId).pipe(
            switchMap((y) => {
                const follow = [new actions.UpdateKpiExportStatusAction(y.status, y.reason, y.progress),
                                new actions.CheckOkKpiExportStatusAction()];
                if (y.status === KPIExportStatuses.InProgress && !t.canceled) {
                    follow.push(new actions.TrackKpiExportStatusAction(x.sectionId, x.targetId));
                }
                return follow;
            }),
            catchError(error => {
                this.logger.error('Could not track KpiExport status', error);
                this.alertService.error(getMessageFromError(error));
                return of(new actions.CheckFailedKpiExportStatusAction(error));
            }),
            takeUntil(this.actions$.pipe(ofAction(actions.CancelCurrentKpiExportTracking)))
        ))
    ));

    constructor(
        private readonly actions$: Actions,
        private readonly alertService: NgxAlertService,
        private readonly logger: NGXLogger,
        private readonly kpiService: KpiService,
        private readonly store: AppStore,
    ) { }
}
